@charset "UTF-8";
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.rounded {
  border-radius: 50%; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

:active, :focus {
  outline: none; }

input, textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 0; }

img {
  max-width: 100%; }

body {
  font: 300 16px "Raleway", sans-serif;
  color: #323232; }
  body a:link, body a:visited, body a:hover {
    text-decoration: none; }

@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?ebkn6");
  src: url("fonts/icomoon.eot?ebkn6#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?ebkn6") format("truetype"), url("fonts/icomoon.woff?ebkn6") format("woff"), url("fonts/icomoon.svg?ebkn6#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.button-array__button--primary, .button-array__button--secondary, .button-array__button, .form-container input[type=submit], .social-links li a, .header__menu-toggle, .footer__nav .nav__item a, .footer__bottom .content-block .credit, .footer__bottom .content-block a {
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

.responsibility-list li {
  position: relative;
  padding-left: 1.2em; }
  .responsibility-list li:before {
    content: "●";
    position: absolute;
    left: 0;
    top: 0; }

.container, .content-block {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  position: relative; }
  @media (max-width: 1199px) {
    .container, .content-block {
      width: 970px; } }
  @media (max-width: 991px) {
    .container, .content-block {
      width: 750px;
      padding-left: 0.66667rem;
      padding-right: 0.66667rem; } }
  @media (max-width: 767px) {
    .container, .content-block {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem; } }

.button-array__button--primary, .button-array__button--secondary, .button-array__button, .form-container input[type=submit] {
  display: inline-block;
  text-align: center;
  padding: 1rem 2rem;
  cursor: pointer;
  font: 1em "Raleway", sans-serif;
  border: 0;
  margin-right: .25rem; }
  .button-array__button--primary:last-child, .button-array__button--secondary:last-child, .button-array__button:last-child, .form-container input[type=submit]:last-child {
    margin-bottom: 0;
    margin-right: 0; }

.button-array__button--primary, .form-container input[type=submit] {
  background: #DAB05C;
  color: white;
  border-bottom: 3px solid #CD9933; }
  .button-array__button--primary:hover, .form-container input[type=submit]:hover {
    background: #d5a647; }

.button-array__button--secondary {
  background: #CD9933;
  color: white;
  border-bottom: 3px solid #b1842b; }
  .button-array__button--secondary:hover {
    background: #b98a2d; }

.button-array {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center; }
  .button-array__bordered {
    border-top: 1px solid;
    padding-top: 1.5rem;
    margin-top: 0; }
  @media (min-width: 992px) {
    .button-array--left-align {
      text-align: left; } }
  @media (max-width: 767px) {
    .button-array__button--secondary {
      margin-top: 1rem;
      margin-right: 0; } }

.social-links li {
  display: inline-block;
  width: 2rem;
  font-size: 0.8em; }
  .social-links li a {
    display: inline-block;
    color: #CD9933;
    width: 2rem; }
    .social-links li a:hover {
      color: #b98a2d; }
    .social-links li a:before {
      display: inline-block;
      font-family: "icomoon";
      font-size: 1.5rem; }
    .social-links li a.facebook:before {
      content: "\ea90"; }
    .social-links li a.twitter:before {
      content: "\ea96"; }

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0; }
  @media (min-width: 992px) {
    .header {
      overflow: hidden; } }
  .header__background {
    background: white; }
  .header__menu-toggle {
    display: none;
    color: #0C0C0C; }
    .header__menu-toggle:before {
      content: "\f0c9";
      font-family: "icomoon" !important;
      font-size: 1.95312em;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1.6rem 1rem 0 0;
      z-index: 6; }
    @media (max-width: 991px) {
      .header__menu-toggle {
        display: block; } }
  .header__logo {
    padding-top: 1rem; }
    @media (min-width: 768px) {
      .header__logo {
        width: 150px;
        float: left;
        margin-right: 4rem; } }
    .header__logo__image {
      width: 150px; }
    @media (max-width: 991px) {
      .header__logo {
        padding-bottom: 1rem; } }
    @media (max-width: 767px) {
      .header__logo {
        text-align: left; } }
  .header__nav {
    color: #4D4D4D;
    padding: 2rem 0; }
    .header__nav .nav {
      overflow: hidden;
      text-align: right; }
      .header__nav .nav__item {
        display: inline-block;
        letter-spacing: .5px;
        padding: 0 1rem; }
        .header__nav .nav__item a {
          display: inline-block;
          text-transform: uppercase;
          color: #4D4D4D;
          transition-delay: 0s;
          transition-duration: 0.15s;
          transition-property: all;
          transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
          font-weight: 400;
          padding-bottom: 5px;
          border-bottom: 2px solid transparent; }
          .header__nav .nav__item a:hover {
            border-bottom: 2px solid #AEAEAE; }
        @media (min-width: 992px) {
          .header__nav .nav__item:last-child {
            padding-right: 0; } }
        .header__nav .nav__item--selected a {
          color: #CD9933; }
          .header__nav .nav__item--selected a:hover {
            border-color: #CD9933; }
        @media (max-width: 991px) {
          .header__nav .nav__item {
            display: block;
            float: none;
            padding: 0.5rem 1rem;
            border-bottom: 1px solid rgba(205, 153, 51, 0.13); }
            .header__nav .nav__item:last-child {
              border: none; } }
      @media (max-width: 991px) {
        .header__nav .nav {
          text-align: center; } }
    @media (min-width: 992px) {
      .header__nav {
        display: block; } }
    @media (max-width: 991px) {
      .header__nav {
        display: none;
        position: absolute;
        text-align: center;
        background: white;
        width: 100%;
        z-index: 5;
        padding: 0;
        margin-top: 67px; } }
    @media (max-width: 767px) {
      .header__nav {
        position: relative;
        margin-top: 0; } }

.footer {
  background: #CD9933; }
  @media (min-width: 768px) {
    .footer {
      padding-top: 3rem; } }
  .footer__logo {
    text-align: center;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    margin-top: 2.5rem; }
    .footer__logo:last-child {
      margin-right: 0; }
    .footer__logo img {
      display: inline-block;
      width: 200px; }
    @media (max-width: 991px) {
      .footer__logo {
        width: 100%;
        margin-bottom: 1.5rem; } }
  .footer__nav {
    text-align: center;
    margin-bottom: 2rem;
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 48.82117%;
    /* Sets into two columns */ }
    .footer__nav:last-child {
      margin-right: 0; }
    .footer__nav ul {
      column-count: 2;
      column-gap: 20px;
      list-style-position: inside;
      text-align: left; }
      @media (max-width: 991px) {
        .footer__nav ul {
          text-align: center; } }
    .footer__nav .content-block__header {
      color: white;
      font-family: "trajan-pro-3", serif;
      font-size: 1.5625em;
      text-align: left;
      border-bottom: 1px white solid;
      margin-bottom: 1rem;
      padding-bottom: 1rem; }
      @media (max-width: 991px) {
        .footer__nav .content-block__header {
          text-align: center; } }
    .footer__nav .nav__item {
      padding: 0.5rem 2rem 0.5rem 0; }
      .footer__nav .nav__item a {
        color: white;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: .5px;
        padding-bottom: 5px;
        border-bottom: 2px solid rgba(255, 255, 255, 0);
        display: inline-block; }
        .footer__nav .nav__item a:hover {
          border-bottom: 2px solid rgba(255, 255, 255, 0.5); }
    @media (max-width: 991px) {
      .footer__nav {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 100%; }
        .footer__nav:last-child {
          margin-right: 0; } }
    @media (max-width: 767px) {
      .footer__nav {
        display: none; } }
  .footer .bottom-divider {
    width: 50%;
    border-color: white; }
  .footer__banded {
    overflow: hidden;
    margin-bottom: 2rem; }
    @media (max-width: 991px) {
      .footer__banded {
        margin-bottom: 0; } }
  .footer__bottom {
    font: 1em "trajan-pro-3", serif;
    overflow: hidden;
    background: white; }
    .footer__bottom .content-block {
      border-top: 1px solid rgba(255, 255, 255, 0.75);
      padding-top: 1rem;
      overflow: hidden; }
      .footer__bottom .content-block .legal {
        float: left; }
      .footer__bottom .content-block .credit {
        float: right; }
      .footer__bottom .content-block a {
        color: #4D4D4D;
        text-decoration: none; }
      .footer__bottom .content-block p {
        font-size: 0.8em; }
        @media (max-width: 767px) {
          .footer__bottom .content-block p {
            margin-bottom: 0; } }
      @media (max-width: 991px) {
        .footer__bottom .content-block {
          padding-bottom: 1rem; }
          .footer__bottom .content-block .legal, .footer__bottom .content-block .credit {
            float: none; } }

.hero {
  padding-top: 12rem;
  padding-bottom: 8rem; }
  .hero__header {
    font: 3.8147em "Raleway", sans-serif;
    color: #4D4D4D;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase; }
    .hero__header strong {
      display: block;
      color: #4D4D4D;
      font-weight: 900; }
    @media (max-width: 991px) {
      .hero__header {
        font-size: 3.05176em; } }
    @media (max-width: 767px) {
      .hero__header {
        font-size: 2.44141em; } }
    .hero__header--light-text {
      color: white; }
    .hero__header--home {
      text-transform: none;
      font-weight: 300; }
    .hero__header:only-child {
      margin-bottom: 0; }
  .hero__subheader {
    color: white;
    font: 1.25em "trajan-pro-3", serif;
    margin-bottom: 1rem; }
  .hero__text {
    color: white;
    font-size: 1em;
    margin-bottom: 1rem; }
    @media (min-width: 992px) {
      .hero__text {
        padding-right: 40%; } }
  @media (min-width: 992px) {
    .hero .button-array {
      text-align: left; } }
  @media (max-width: 991px) {
    .hero {
      text-align: center;
      padding-top: 8rem;
      padding-bottom: 7rem; } }

.hero--home {
  background: url(/images/hero.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 2rem; }

.hero--about {
  background: url(/images/hero_about.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 0; }

.hero--stories {
  background: url(/images/hero_stories.jpg) center center no-repeat;
  background-size: cover;
  padding-bottom: 6rem;
  margin-bottom: 2rem; }
  @media (max-width: 991px) {
    .hero--stories {
      padding-bottom: 4rem; } }

.hero--faq {
  background: url(/images/hero_stories.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 0; }

.hero--forms {
  background: url(/images/hero_forms.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 0; }

.hero--contact {
  background: url(/images/hero_about.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 0; }

.hero--management {
  background: url(/images/hero_management.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 0; }

.hero--loans {
  background: url(/images/hero_churchloans.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 0; }

main {
  background: #F1F1F1; }
  main.background-white {
    background: white; }

.content-block p {
  margin-bottom: 1em;
  line-height: 1.6; }
  .content-block p a {
    color: #CD9933;
    text-decoration: underline; }
    .content-block p a:hover {
      color: #b98a2d; }
  @media (max-width: 991px) {
    .content-block p {
      margin-bottom: 2rem; } }

@media (max-width: 991px) {
  .content-block {
    text-align: center; } }

.content-block__header {
  font: bold 3.05176em "Raleway", sans-serif;
  margin-bottom: 1.5rem;
  color: #CD9933;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .content-block__header {
      font-size: 1.95312em;
      overflow: hidden; } }
  .content-block__header--align-center {
    text-align: center; }
  .content-block__header-footer {
    text-align: left;
    color: white;
    font: bold 1.5625em "trajan-pro-3", serif; }
  .content-block__header--light {
    color: white; }
    @media (max-width: 991px) {
      .content-block__header--light {
        color: #CD9933; } }
  .content-block__header--square {
    font-size: 1.25em;
    font-family: "trajan-pro-3", serif;
    position: relative; }
    @media (max-width: 991px) {
      .content-block__header--square {
        font-size: 1.5625em; } }
    .content-block__header--square:before {
      content: attr(data-number);
      display: inline-block;
      margin-right: 0.6em;
      width: 2.44141em;
      height: 2.44141em;
      line-height: 2.44141em;
      text-align: center;
      color: white;
      background-image: url("/images/diamond.svg");
      background-size: cover; }
      @media (max-width: 767px) {
        .content-block__header--square:before {
          display: block;
          margin: 0 auto; } }
  .content-block__header--square-small {
    font-family: "trajan-pro-3", serif;
    position: relative;
    color: white; }
    @media (min-width: 992px) {
      .content-block__header--square-small {
        font-size: 1em;
        padding-left: calc(3.05176em + (2rem / 4)); } }
    @media (max-width: 991px) {
      .content-block__header--square-small {
        font-size: 1.25em; } }
    .content-block__header--square-small:before {
      content: attr(data-number);
      display: inline-block;
      margin-right: 0.6em;
      width: 3.05176em;
      height: 3.05176em;
      text-align: center;
      color: white;
      line-height: 3.05176em;
      background-image: url("/images/diamond.svg");
      background-size: cover; }
      @media (min-width: 992px) {
        .content-block__header--square-small:before {
          position: absolute;
          top: 0;
          left: 0; } }
      @media (max-width: 767px) {
        .content-block__header--square-small:before {
          display: block;
          margin: 0 auto;
          margin-bottom: 1rem; } }
  .content-block__header--circle {
    margin-top: 1.5rem;
    font-size: 1.5625em;
    font-family: "trajan-pro-3", serif; }
    .content-block__header--circle:first-child {
      margin-top: 0; }
    .content-block__header--circle:before {
      content: attr(data-number);
      display: inline-block;
      margin-right: 0.6em;
      width: 1.5625em;
      line-height: 1.5625em;
      text-align: center;
      border-radius: 50%;
      border: 1px solid #CD9933; }
      @media (max-width: 767px) {
        .content-block__header--circle:before {
          display: block;
          margin: 0 auto; } }

.content-block__subheader {
  font: 1em "trajan-pro-3", serif;
  color: #AEAEAE;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  margin-top: -.45rem; }
  .content-block__subheader--align-center {
    text-align: center; }
  .content-block__subheader--light {
    color: #AEAEAE; }

.wide-wrapper {
  margin: 3rem auto;
  padding: 2rem 6rem;
  overflow: hidden;
  background: #F1F1F1;
  width: 1362px; }
  @media (max-width: 1362px) {
    .wide-wrapper {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 991px) {
    .wide-wrapper {
      margin-top: 2rem; } }
  .wide-wrapper--split-image-left, .wide-wrapper--split-image-right {
    position: relative; }
    .wide-wrapper--split-image-left .content-block, .wide-wrapper--split-image-right .content-block {
      z-index: 2; }
    .wide-wrapper--split-image-left:after, .wide-wrapper--split-image-right:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      background-size: cover;
      width: 50%;
      height: 100%;
      z-index: 1; }
      @media (max-width: 991px) {
        .wide-wrapper--split-image-left:after, .wide-wrapper--split-image-right:after {
          display: none; } }
  .wide-wrapper--split-image-left:after {
    background: url(/images/church.jpg) center center no-repeat;
    left: 0; }
  .wide-wrapper--split-image-right:after {
    background: url(/images/work.jpg) center center no-repeat;
    right: 0; }
  .wide-wrapper--no-margin {
    margin-top: 0; }
  .wide-wrapper--no-pad {
    padding-left: 0;
    padding-right: 0; }
  .wide-wrapper--tri-split {
    background: #4D4D4D;
    position: relative;
    margin-bottom: 0; }
    .wide-wrapper--tri-split:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: #4D4D4D;
      background-size: cover;
      width: 100%;
      height: 100%;
      z-index: -1; }
    .wide-wrapper--tri-split p {
      margin-bottom: 1rem; }
  .wide-wrapper .content-block--split__right {
    padding: 3rem; }
    @media (max-width: 991px) {
      .wide-wrapper .content-block--split__right {
        padding: 2rem; } }
    @media (max-width: 767px) {
      .wide-wrapper .content-block--split__right {
        padding: 0; } }
  .wide-wrapper--cards {
    margin-top: -4rem;
    background: white;
    -webkit-box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3);
    -moz-box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3);
    box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3); }
    .wide-wrapper--cards .content-block--split__right {
      padding: 0; }
    .wide-wrapper--cards .testimonial {
      padding: 0; }
    @media (max-width: 991px) {
      .wide-wrapper--cards {
        padding-top: 1rem;
        padding-bottom: 1rem; }
        .wide-wrapper--cards .testimonial {
          margin-top: 2rem;
          padding-left: 1rem;
          padding-right: 1rem; }
          .wide-wrapper--cards .testimonial__avatar {
            margin-top: 0; }
          .wide-wrapper--cards .testimonial__text {
            padding-bottom: 0; } }

.form-container {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto; }
  .form-container::after {
    clear: both;
    content: "";
    display: table; }
  .form-container label {
    display: none; }
  .form-container input:not([type='submit']), .form-container textarea {
    width: 100%;
    background: #F0F1F2;
    border: 2px solid #DEDFE0;
    padding: 0.5rem;
    margin: 0 0 1rem;
    font: 1em "Raleway", sans-serif; }
  .form-container textarea {
    height: 10em; }

.alert {
  padding: 1rem;
  margin: 0 0 1rem;
  text-align: center;
  color: white; }
  .alert__header {
    font: bold 1.5625em "Raleway", sans-serif; }
    .alert__header:before {
      font-family: "icomoon";
      font-size: 1.5625em;
      margin: 0 0 2rem;
      color: white;
      display: block; }
  .alert__message {
    margin: 0; }
  .alert__items {
    margin-top: 1rem; }
  .alert.alert--danger {
    background: #C63530; }
    .alert.alert--danger .alert__header:before {
      content: "\ea07"; }
  .alert.alert--success {
    background: #057A2D; }
    .alert.alert--success .alert__header:before {
      content: "\ea10"; }
  .alert.alert--notice {
    background: rgba(205, 153, 51, 0.75); }
    .alert.alert--notice .alert__header:before {
      content: "\ea07"; }

@media (min-width: 992px) {
  .content-block--split {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto; }
    .content-block--split::after {
      clear: both;
      content: "";
      display: table; }
    .content-block--split__left {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      position: relative; }
      .content-block--split__left:last-child {
        margin-right: 0; }
    .content-block--split__right {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 48.82117%;
      position: relative; }
      .content-block--split__right:last-child {
        margin-right: 0; } }

@media (max-width: 991px) {
  .content-block--split {
    margin-bottom: 1rem; }
    .content-block--split__left {
      margin-bottom: 2rem; } }

.content-block--card {
  border-bottom: 1px #DEDFE0 solid;
  background: white;
  overflow: hidden; }
  .content-block--card p {
    margin-bottom: 2rem; }
    @media (max-width: 991px) {
      .content-block--card p {
        margin-bottom: 1rem; } }
  .content-block--card:first-child .content-block--card__header {
    padding-top: 0; }
  .content-block--card:last-child {
    border: 0; }
    .content-block--card:last-child p {
      margin-bottom: 0; }
  .content-block--card .testimonial {
    margin-top: 0;
    padding-left: 0;
    padding-top: 2rem; }
  .content-block--card__header {
    font-family: "trajan-pro-3", serif;
    font-size: 1.5625em;
    color: #DAB05C;
    margin-bottom: 1rem;
    padding-top: 2rem; }
    @media (max-width: 991px) {
      .content-block--card__header {
        padding-top: 1rem; } }
  .content-block--card form {
    margin-bottom: 2rem; }
  .content-block--card__alone {
    padding: 1rem 0; }

.content-block--story {
  margin-bottom: 2rem;
  background: white;
  overflow: hidden;
  -webkit-box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3);
  -moz-box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3);
  box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3); }
  .content-block--story__left {
    margin-right: 40%; }
  .content-block--story__right {
    margin-left: 40%; }
  .content-block--story__header {
    padding-top: 2rem;
    padding-bottom: 1rem;
    color: #DAB05C;
    font: 1.5625em "trajan-pro-3", serif; }
    @media (max-width: 991px) {
      .content-block--story__header {
        padding-bottom: 0;
        padding-top: calc(100px + 3rem + (2rem / 2)); } }
  .content-block--story__subheader {
    padding-bottom: 1rem;
    color: #4D4D4D; }
  .content-block--story__text {
    color: #AEAEAE;
    padding-right: 1rem;
    padding-bottom: 2rem; }
  .content-block--story__header, .content-block--story__subheader, .content-block--story__text {
    padding-left: calc(150px + 3rem + (2rem / 2)); }
    @media (max-width: 991px) {
      .content-block--story__header, .content-block--story__subheader, .content-block--story__text {
        padding-left: 1rem;
        padding-right: 1rem; } }
  .content-block--story__avatar {
    width: 150px;
    float: left;
    overflow: hidden;
    border-radius: 50%;
    border: 2px #CD9933 solid;
    position: absolute;
    margin-left: 2rem;
    margin-top: 4rem; }
    @media (max-width: 991px) {
      .content-block--story__avatar {
        margin: 2.25rem auto 0 auto;
        left: 0;
        right: 0; } }
  @media (max-width: 991px) {
    .content-block--story__right, .content-block--story__left {
      margin-left: 0;
      margin-right: 0; }
    .content-block--story__avatar {
      margin: 1rem auto 0 auto; }
    .content-block--story__text {
      padding-bottom: 0; } }

.content-block--prefooter {
  text-align: center;
  width: 600px;
  padding: 2rem;
  -webkit-box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3);
  -moz-box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3);
  box-shadow: 0px 1px 5px 1px rgba(48, 48, 48, 0.3);
  background: white; }
  .content-block--prefooter p {
    margin-bottom: 2rem; }
  .content-block--prefooter .button-array {
    margin-bottom: 0;
    margin-top: 0; }
  @media (max-width: 991px) {
    .content-block--prefooter {
      width: 100%; }
      .content-block--prefooter .button-array__button--secondary {
        margin-right: 0;
        margin-top: 1rem; } }

.content-block--padded-both {
  padding: 2rem 0; }

.content-block--church-loans .content-block--split__left {
  padding-top: 160px; }
  @media (max-width: 991px) {
    .content-block--church-loans .content-block--split__left {
      padding-top: 40px; } }

.content-block--church-loans .content-block__header--square-number__number {
  font-size: 1em; }

.content-block--church-loans .button-array__bordered {
  border-color: #AEAEAE; }

.content-block--work .content-block--split__left {
  padding-top: 6em;
  padding-bottom: 6em; }
  @media (max-width: 991px) {
    .content-block--work .content-block--split__left {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
      margin-bottom: 0; } }

.content-block--work .button-array__bordered {
  border-color: #AEAEAE; }

@media (min-width: 992px) {
  .content-block--tri-split {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto; }
    .content-block--tri-split::after {
      clear: both;
      content: "";
      display: table; }
    .content-block--tri-split__left {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
      text-align: left;
      color: white;
      position: relative; }
      .content-block--tri-split__left:last-child {
        margin-right: 0; }
    .content-block--tri-split__middle {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
      text-align: left;
      color: white;
      position: relative; }
      .content-block--tri-split__middle:last-child {
        margin-right: 0; }
    .content-block--tri-split__right {
      float: left;
      display: block;
      margin-right: 2.35765%;
      width: 31.76157%;
      text-align: left;
      color: white;
      position: relative; }
      .content-block--tri-split__right:last-child {
        margin-right: 0; } }

@media (max-width: 991px) {
  .content-block--tri-split__text {
    clear: both;
    color: white;
    padding-bottom: 1rem; } }

.content-block__bordered {
  overflow: hidden; }
  .content-block__bordered--top {
    padding-top: 2rem;
    border-top: 1px #AEAEAE solid; }
  .content-block__bordered--bottom {
    border-bottom: 1px #AEAEAE solid;
    padding-bottom: 2rem; }
  .content-block__bordered--both {
    border-top: 1px #AEAEAE solid;
    border-bottom: 1px #AEAEAE solid;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 1rem; }
    @media (max-width: 991px) {
      .content-block__bordered--both {
        padding-bottom: 0; } }

/* banded */
.banded {
  margin-top: 4rem;
  padding-top: 3rem;
  padding-bottom: 3rem; }
  .banded--image {
    background: url(/images/prefooter.jpg) center center no-repeat;
    background-color: grey;
    background-size: cover; }
    @media (max-width: 991px) {
      .banded--image {
        background-image: none; } }
  @media (max-width: 991px) {
    .banded {
      padding-top: 0;
      padding-bottom: 0; } }

/* Testimonial Section */
.testimonial {
  text-align: center;
  overflow: hidden;
  padding-left: 2rem;
  margin-top: 2rem; }
  .testimonial.container {
    padding-left: 0; }
  .testimonial__header {
    font: bold 1.95312em "Raleway", sans-serif;
    margin-bottom: 0.5rem;
    color: white;
    text-align: left;
    margin-top: 1rem; }
    .testimonial__header--dark {
      color: #4D4D4D; }
    @media (max-width: 991px) {
      .testimonial__header {
        text-align: center;
        color: #4D4D4D; } }
  .testimonial__subheader {
    font: 1em "Raleway", sans-serif;
    margin-bottom: 1rem;
    color: #CD9933;
    text-align: left;
    font-style: italic; }
    @media (max-width: 991px) {
      .testimonial__subheader {
        overflow: hidden;
        text-align: center; } }
  .testimonial__text {
    text-align: left;
    font-style: italic;
    font-size: 1.5625em;
    clear: both;
    padding-top: 2rem;
    color: white; }
    .testimonial__text--dark {
      color: #4D4D4D;
      font-size: 1.25em; }
    @media (max-width: 991px) {
      .testimonial__text {
        color: #4D4D4D;
        text-align: center;
        padding-bottom: 2rem; } }
  .testimonial__avatar {
    width: 100px;
    float: left;
    margin-right: 1rem;
    border-radius: 50%;
    border: 2px #CD9933 solid; }
    @media (max-width: 991px) {
      .testimonial__avatar {
        margin-top: 1.7rem;
        float: none;
        margin-right: 0; } }
  @media (max-width: 991px) {
    .testimonial {
      text-align: center;
      padding-top: 0;
      padding-left: 0;
      margin-top: 0; } }

.form-download {
  padding-top: 2rem; }

@media (min-width: 992px) {
  .responsibility-list {
    column-count: 2;
    column-gap: 3rem; } }

.responsibility-list li {
  display: inline-block;
  width: 100%;
  margin-bottom: 1rem; }

@media (max-width: 991px) {
  .responsibility-list {
    text-align: left; } }
