@import 'bourbon','neat';

// --------------------------------------
//
//   SITE VARIABLES
//
// --------------------------------------

// Fonts
$font-header: 'Raleway', sans-serif;
$font-body: 'Raleway', sans-serif;
$serif: 'trajan-pro-3', serif;

// Colors
$color1: #CD9933;
$color1-hover: darken($color1,5%);

$color2: #F1F1F1;
$color2-hover: darken($color2,5%);

$color3: #4D4D4D;
$color3-hover: darken($color3,5%);

$color4: #AEAEAE;
$color4-hover: darken($color4,5%);

$color5: #DAB05C;
$color5-hover: darken($color5,5%);

$color-border-light: #DEDFE0;

$color-black: #0C0C0C;
$color-text: #323232;

$color-background-light: #F0F1F2;
$color-background-grey: #949599;

$color-error: #C63530;
$color-success: #057A2D;

// Distance
$unit: 2rem;

// Shadows
@mixin shadow-3 {
  box-shadow: 0 .3rem .6rem rgba(0,0,0,.25);
}
%shadow-3 {
  @include shadow-3;
}
@mixin shadow-2 {
  box-shadow: 0 .2rem .4rem rgba(0,0,0,.2);
}
%shadow-2 {
  @include shadow-2;
}
@mixin shadow-1 {
  box-shadow: 0 .1rem .2rem rgba(0,0,0,.12);
}
%shadow-1 {
  @include shadow-1;
}

// Border Radiuses
.rounded {
  border-radius: 50%;
}

// Modular Scale
$modular-scale-ratio: 1.25;

// --------------------------------------
//
//      RESETS
//
// --------------------------------------

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{border:0;font-size:100%;font:inherit;margin:0;padding:0}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:none}table{border-collapse:collapse;border-spacing:0}
:active, :focus {
  outline: none;
}

input, textarea, select {
  @include appearance(none);
  border-radius: 0;
}
img {
  max-width: 100%;
}

// --------------------------------------
//
//      BODY
//
// --------------------------------------

body {
  font: 300 16px $font-body;
  color: $color-text;
  a:link, a:visited, a:hover {
    text-decoration: none;
  }
}

// --------------------------------------
//
//      ICOMOON
//
// --------------------------------------

@font-face {
  font-family: 'icomoon';
  src:    url('fonts/icomoon.eot?ebkn6');
  src:    url('fonts/icomoon.eot?ebkn6#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?ebkn6') format('truetype'),
  url('fonts/icomoon.woff?ebkn6') format('woff'),
  url('fonts/icomoon.svg?ebkn6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    // use !important to prevent issues with browser extensions that change fonts 
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

// --------------------------------------
//
//   UTILITY MIXINS
//
// --------------------------------------

%box-model {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

// Transitions
@mixin transition--medium {
  transition-delay: 0s;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
%transition--medium {
  @include transition--medium;
}
@mixin transition--fast {
  transition-delay: 0s;
  transition-duration: 0.15s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}
%transition--fast {
  @include transition--fast;
}

// No Selection Highlighting
%no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;    
}

// Vertical Align
@mixin vertical-align-center {
  top: 50%;
  transform: translateY(-50%);  
}
%vertical-align-center {
  @include vertical-align-center;
}

// bullet
%bulleted {
  position: relative;
  padding-left: 1.2em;

  &:before {
    content: "●";
    position: absolute;
    left: 0;
    top: 0;
  }
}

// --------------------------------------
//
//   RESPONSIVE MIXINS
//
// --------------------------------------

@mixin md {
  @media (max-width: 1199px){
    @content;
  }
}
@mixin sm {
  @media (max-width: 991px){
    @content;
  }
}
@mixin xs {
  @media (max-width: 767px){
    @content;
  }
}
@mixin xxs {
  @media (max-width: 400px){
    @content;
  }
}
@mixin sm-and-up {
  @media (min-width: 768px){
    @content;
  }
}
@mixin md-and-up {
  @media (min-width: 992px){
    @content;
  }
}
@mixin lg-and-up {
  @media (min-width: 1200px){
    @content;
  }
}
@mixin xlg-and-up {
  @media (min-width: 1600px){
    @content;
  }
}
@mixin wide-wrap-break {
  @media (max-width: 1362px){
    @content;
  }
}

// set variable for bourbon neat
$max-width: 1170px;

// --------------------------------------
//
//   SITE-SPECIFIC MIXINS
//
// --------------------------------------

// --------------------------------------
//
//      GRID
//
// --------------------------------------

// Override Neat's grid settings
$column: modular-scale(3) !default; // Column width
$gutter: modular-scale(3) !default; // Gutter between each two columns

// Set Bourbon's flex-grid variables to match what Neat is doing
// http: //bourbon.io/docs/#flex-grid
$fg-column: $column;
$fg-gutter: $gutter;

// Omega Reset
@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}


@mixin container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @include md {
    width: 970px;
  }

  @include sm {
    width: 750px;
    padding-left: $unit/3;
    padding-right: $unit/3;
  }

  @include xs {
    width: 100%;
    padding-left: $unit/2;
    padding-right: $unit/2;    
  }  
}
%container {
  @include container;
}
.container {
  @extend %container;
}

// --------------------------------------
//
//      BUTTONS
//
// --------------------------------------

%button {
  @extend %transition--medium;
  display: inline-block;
  text-align: center;
  padding: $unit/2 $unit;
  cursor: pointer;
  font: 1em $font-body;
  border: 0;
  margin-right: .25rem;

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }
}
%button--primary {
  background: $color5;
  color: white;

  border-bottom: 3px solid $color1;

  &:hover {
    background: $color5-hover;
  }
}
%button--secondary {
  background: $color1;
  color: white;
  border-bottom: 3px solid darken($color1, 7%);

  &:hover {
    background: $color1-hover;
  }
}

.button-array {
  margin-top: $unit/2;
  margin-bottom: $unit/2;
  text-align: center;

  &__bordered {
    border-top: 1px solid;
    padding-top: $unit*.75;
    margin-top: 0;
  }

  &--left-align {
    @include md-and-up {
      text-align: left;
    }
  }

  &__button--primary {
    @extend %button;
    @extend %button--primary;
  }
  &__button--secondary {
    @extend %button;
    @extend %button--secondary;

    @include xs {
      margin-top: $unit/2;
      margin-right: 0;
    }
  }
  &__button {
    @extend %button;
  }
}


// --------------------------------------
//
//      SOCIAL LINKS
//
// --------------------------------------

.social-links {
  li {
    display: inline-block;
    width: $unit;
    font-size: modular-scale(-1);

    a {
      @extend %transition--medium;
      display: inline-block;
      color: $color1;
      width: $unit;

      &:hover {
        color: $color1-hover;
      }

      &:before {
        display: inline-block;
        font-family: "icomoon";
        font-size: 1.5rem;
      }

      &.facebook:before {
        content: "\ea90";
      }
      &.twitter:before {
        content: "\ea96";
      }
    }
  }
}

// --------------------------------------
//
//      HEADER
//
// --------------------------------------

.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;

  @include md-and-up {
    overflow: hidden;
  }

  &__background {
    background:white;
  }

  &__menu-toggle {
    display: none;

    @extend %transition--medium;
    color: $color-black;

    &:before {
      content: "\f0c9";
      font-family: "icomoon" !important;
      font-size: modular-scale(3);
      position: absolute;
      top: 0;
      right: 0;
      padding: $unit/1.25 $unit/2 0 0;
      z-index:6;
    }

    @include sm {
      display: block;
    }
  }

  &__logo {
    padding-top: $unit/2;
    @include sm-and-up {
      width: 150px;
      float: left;
      margin-right: $unit*2;
    }

    &__image {
      width: 150px;
    }
    @include sm {
      padding-bottom: $unit/2;
    }
    @include xs {
      text-align: left;
    }
  }

  &__nav {
    color: $color3;
    padding: $unit 0;

    .nav {
      overflow: hidden;
      text-align: right;

      &__item {
        display: inline-block;
        letter-spacing: .5px;
        padding: 0 $unit/2;

        a {
          display: inline-block;
          text-transform: uppercase;
          color:$color3;
          @include transition--fast;
          font-weight: 400;
          padding-bottom: 5px;
          border-bottom: 2px solid transparent;

          &:hover {
            border-bottom: 2px solid $color4;
          }
        }

        &:last-child {
          @include md-and-up {
            padding-right: 0;
          }
        }

        &--selected a {
          color: $color1;

          &:hover {
            border-color: $color1;
          }
        }

        @include sm {
          display: block;
          float: none;
          padding: $unit/4 $unit/2;
          border-bottom: 1px solid fade-out($color1,.87);

          &:last-child {
            border: none;
          }
        }
      }

      @include sm {
        text-align: center;
      }
    }

    @include md-and-up {
      display: block;
    }

    @include sm {
      display:none;
      position: absolute;
      text-align: center;
      background: white;
      width: 100%;
      z-index: 5;
      padding: 0;
      margin-top: 67px;
    }
    @include xs {
      position:relative;
      margin-top:0;
    }
  }
}

// --------------------------------------
//
//      FOOTER
//
// --------------------------------------

.footer {

  background: $color1;

  @include sm-and-up {
    padding-top: $unit*1.5;
  }

  &__logo {
    text-align: center;
    @include span-columns(6);
    margin-top: $unit*1.25;

    img {
      display: inline-block;
      width: 200px;
    }

    @include sm {
      width: 100%;
      margin-bottom:$unit*.75;
    }
  }

  &__nav {
    text-align: center;
    margin-bottom: $unit;
    @include span-columns(6);
    
    /* Sets into two columns */
    ul {
      column-count: 2;
      column-gap: 20px;
      list-style-position: inside;
      text-align: left;

      @include sm {
        text-align: center;
      }
    }

    .content-block__header {
      color:white;
      font-family: $serif;
      font-size: modular-scale(2);
      text-align: left;
      border-bottom: 1px white solid;

      margin-bottom: $unit/2;
      padding-bottom: $unit/2;

      @include sm {
        text-align: center;
      }
    }

    .nav__item {
      padding: $unit/4 $unit $unit/4 0;
      

      a {
        color: white;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: .5px;
        padding-bottom: 5px;
        border-bottom: 2px solid rgba(255,255,255,0);
        display: inline-block;

        @extend %transition--medium;

        &:hover {
          border-bottom: 2px solid rgba(255,255,255,.5);
        }        
      }
    }

    @include sm {
      @include span-columns(12);
    }

    @include xs {
      display: none;
    }
  }

  .bottom-divider {
    width: 50%;
    border-color: white;
  }

  &__banded {
    overflow: hidden;
    margin-bottom: $unit;

    @include sm {
      margin-bottom: 0;
    }
  }
  &__bottom {
    font: 1em $serif;
    overflow: hidden;
    background: white;

    .content-block {
      border-top: 1px solid rgba(255,255,255,.75);
      padding-top: $unit/2;
      
      overflow: hidden;

      .legal {
        float: left;
      }
      .credit {
        float: right;
        @extend %transition--medium;
      }

      a {
        color: $color3;
        text-decoration: none;
        @extend %transition--medium;
      }

      p {
        font-size: modular-scale(-1);

        @include xs {
          margin-bottom: 0;
        }
      }

      @include sm {
        padding-bottom: $unit/2;
        .legal, .credit {
          float:none;
        }
      }
    }
  }
}

// --------------------------------------
//
//     HERO
//
// --------------------------------------

.hero {
  padding-top: $unit*6;
  padding-bottom: $unit*4;

  &__header {
    font: modular-scale(6) $font-body;
    color: $color3;
    font-weight: 700;
    margin-bottom: $unit/2;
    text-transform: uppercase;

    strong {
      display: block;
      color: $color3;
      font-weight: 900;
    }

    @include sm {
      font-size: modular-scale(5);
    }

    @include xs {
      font-size: modular-scale(4);
    }

    &--light-text {
      color:white;
    }

    &--home {
      text-transform: none;
      font-weight: 300;
    }

    &:only-child {
      margin-bottom: 0;
    }

    &:only-of-type {}
  }

  &__subheader {
    color:white;
    font: modular-scale(1) $serif;
    margin-bottom:$unit/2;
  }

  &__text {
    color: white;
    font-size: modular-scale(0);
    margin-bottom: $unit/2;

    @include md-and-up {
      padding-right: 40%;
    }    
  }

  .button-array {
    @include md-and-up {
      text-align: left;
    }
  }

  @include sm {
    text-align: center;
    padding-top: $unit*4;
    padding-bottom: $unit*3.5;
  }
}

.hero--home {
  background: url(/images/hero.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: $unit;
}

.hero--about {
  background: url(/images/hero_about.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom: 0;
}

.hero--stories {
  background: url(/images/hero_stories.jpg) center center no-repeat;
  background-size: cover;
  padding-bottom: $unit*3;
  margin-bottom: $unit;
  @include sm {
    padding-bottom:$unit*2;
  }
}

.hero--faq {
  background: url(/images/hero_stories.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom:0;
}

.hero--forms {
  background: url(/images/hero_forms.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom:0;
}

.hero--contact {
  background: url(/images/hero_about.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom:0;
}

.hero--management {
  background: url(/images/hero_management.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom:0;
}

.hero--loans {
  background: url(/images/hero_churchloans.jpg) center center no-repeat;
  background-size: cover;
  margin-bottom:0;
}

// --------------------------------------
//
//     MAIN
//
// --------------------------------------
main {
  background: $color2;

  &.background-white {
    background: white;
  }
}

// --------------------------------------
//
//     CONTENT BLOCK
//
// --------------------------------------

%content-block {
  @extend %container;

  p {
    margin-bottom: 1em;
    line-height: 1.6;

    a {
      color: $color1;
      text-decoration: underline;

      &:hover {
        color: $color1-hover;
      }
    }
    @include sm {
      margin-bottom: $unit;
    }
  }

  @include sm {
    text-align: center;
  }
}

.content-block {
  @extend %content-block;

  &__header {
    font: bold modular-scale(5) $font-header;
    margin-bottom: $unit*.75;
    color: $color1;
    text-transform: uppercase;

    @include xs {
      font-size: modular-scale(3);
      overflow: hidden;
    }

    &--align-center {
      text-align: center;
    }

    &-footer {
      text-align: left;
      color: white;
      font: bold modular-scale(2) $serif;
    }

    &--light {
      color: white;

      @include sm {
        color: $color1;
      }
    }

    $diamond-size: modular-scale(4);

    &--square {
      font-size: modular-scale(1);
      font-family: $serif;
      position: relative;

      @include sm {
        font-size: modular-scale(2);
      }

      &:before {
        content: attr(data-number);
        display: inline-block;
        margin-right: 0.6em;
        width: $diamond-size;
        height: $diamond-size;
        line-height: $diamond-size;
        text-align: center;
        color: white;
        background-image: url('/images/diamond.svg');
        background-size: cover;

        @include xs {
          display: block;
          margin: 0 auto;
        }
      }
    }

    $diamond-small-size: modular-scale(5);

    &--square-small {
      font-family: $serif;
      position: relative;
      color: white;

      @include md-and-up {
        font-size: modular-scale(0);
        padding-left: calc(#{$diamond-small-size} + (#{$unit} / 4));
      }

      @include sm {
        font-size: modular-scale(1);
      }

      &:before {
        content: attr(data-number);
        display: inline-block;
        margin-right: 0.6em;
        width: $diamond-small-size;
        height: $diamond-small-size;
        text-align: center;
        color: white;
        line-height: $diamond-small-size;

        background-image: url('/images/diamond.svg');
        background-size: cover;

        @include md-and-up {
          position: absolute;
          top: 0;
          left: 0;
        }

        @include xs {
          display: block;
          margin: 0 auto;
          margin-bottom: $unit/2;
        }
      }
    }
    
    $circle-size: modular-scale(2);

    &--circle {
      margin-top: $unit*0.75;

      &:first-child {
        margin-top: 0;
      }   

      font-size: modular-scale(2);
      font-family: $serif;

      &:before {
        content: attr(data-number);
        display: inline-block;
        margin-right: 0.6em;
        width: $circle-size;

        line-height: $circle-size;
        text-align: center;

        border-radius: 50%;
        border:1px solid $color1;

        @include xs {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  &__subheader {
    font: modular-scale(0) $serif;
    color: $color4;
    text-transform: uppercase;
    margin-bottom: $unit*.75;
    margin-top: -.45rem;

    &--align-center {
      text-align: center;
    }

    &--light {
      color: $color4;
    }
  }
}

// for content-blocks that stretch 6rem wider than the 1170 container (put container inside wide-wrapper)
.wide-wrapper {
  margin: $unit*1.5 auto;
  padding: $unit $unit*3;
  overflow: hidden;
  background: $color2;

  width: 1362px; // 1170w plus 96px (3rem) on each side

  @include wide-wrap-break {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @include sm {
    margin-top: $unit;
  }

  &--split-image-left, &--split-image-right {
    position: relative;

    .content-block {
      z-index: 2;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      background-size: cover;
      width: 50%;
      height: 100%;
      z-index: 1;
      @include sm {
        display: none;
      }
    }
  }
  &--split-image-left:after {
    background: url(/images/church.jpg) center center no-repeat;
    left:0;
  }
  &--split-image-right:after {
    background: url(/images/work.jpg) center center no-repeat;
    right:0;
  }

  &--no-margin {
    margin-top: 0;
  }

  &--no-pad {
    padding-left: 0;
    padding-right: 0;
  }

  &--tri-split {
    background: $color3;
    position: relative;
    margin-bottom: 0;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      background: $color3;
      background-size: cover;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    p {
      margin-bottom: $unit/2;
    }
  }
  
  .content-block--split {
    //margin-bottom: 0;

    &__right {
      padding: $unit*1.5;

      @include sm {
        padding: $unit;
      }

      @include xs {
        padding: 0;
      }
    }
  }

  &--cards {
    //padding-top: $unit*2;
    margin-top: -$unit*2;
    background: white;

    -webkit-box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);
    -moz-box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);
    box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);

    .content-block--split {
      &__right {
        padding: 0;
      }
    }

    .testimonial {
      padding: 0;
    }

    @include sm {
      padding-top: $unit/2;
      padding-bottom: $unit/2;

      .testimonial {
        margin-top: $unit;
        padding-left: $unit/2;
        padding-right: $unit/2;

        &__avatar {
          margin-top: 0;
        }

        &__text {
          padding-bottom: 0;
        }
      }
    }
  }
}
// --------------------------------------
//
//    FORM: GENERAL
//
// --------------------------------------
.form-container {
  @include outer-container();

  label {
    display: none;
  }

  input:not([type='submit']),textarea {
    width: 100%;
    background: $color-background-light;
    border: 2px solid $color-border-light;
    padding: $unit/4;
    margin: 0 0 $unit/2;
    font: 1em $font-body;
  }
  input[type=submit]{
    @extend %button;
    @extend %button--primary;
  }
  textarea {
    height: 10em;
  }
}

// --------------------------------------
//
//    ALERT
//
// --------------------------------------
.alert {
  padding: $unit/2;
  margin: 0 0 $unit/2;
  text-align: center;
  color: white;

  &__header {
    font: bold modular-scale(2) $font-body;

    &:before {
      font-family: "icomoon";
      font-size: modular-scale(2);
      margin: 0 0 $unit;
      color: white;
      display: block;
    }
  }
  &__message {
    margin: 0;
  }
  &__items {
    margin-top: $unit/2;
  }

  &.alert--danger {
    background: $color-error;

    .alert__header {
      &:before {
        content: "\ea07"; 
      }
    }
  }
  &.alert--success {
    background: $color-success;

    .alert__header {
      &:before {
        content: "\ea10";
      }
    }
  }
  &.alert--notice {
    background: fade-out($color1,.25);

    .alert__header {
      &:before {
        content: "\ea07"; 
      }
    }    
  }
}

// ----------------------------------------------------------------------------
//
//
//
//                            SITE SPECIFIC CONTENT
//
//
//
// ----------------------------------------------------------------------------
.content-block {
  &--split {
    @include md-and-up {
      @include outer-container();
      //margin-bottom: $unit;

      &__left {
        @include span-columns(6);
        position: relative;
      }

      &__right {
        @include span-columns(6);
        position: relative;
      }
    }

    @include sm {
      margin-bottom: $unit/2;
      &__left {
        margin-bottom: $unit;
      }
    }
  }

  &--card {
    border-bottom: 1px $color-border-light solid;
    background: white;
    overflow: hidden;

    p {
      margin-bottom: $unit;

      @include sm {
        margin-bottom: $unit/2;
      }
    }

    &:first-child {
      .content-block--card__header {
        padding-top: 0;
      }
    }

    &:last-child {
      border: 0;
      p {
        margin-bottom: 0;
      }
    }

    .testimonial {
      margin-top: 0;
      padding-left: 0;
      padding-top: 2rem;
    }

    &__header {
      font-family: $serif;
      font-size: modular-scale(2);
      color: $color5;
      margin-bottom: $unit/2;
      padding-top: $unit;

      @include sm {
        padding-top: $unit/2;
      }
    }

    form {
      margin-bottom: $unit;
    }

    &__alone {
      padding: $unit/2 0;
    }
  }

  &--story {
    margin-bottom: $unit;
    background: white;
    overflow: hidden;
    -webkit-box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);
    -moz-box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);
    box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);

    &__left {
      margin-right: 40%;
    }

    &__right {
      margin-left: 40%;
    }

    &__header {
      padding-top: $unit;
      padding-bottom: $unit/2;

      color: $color5;
      font: modular-scale(2) $serif;

      @include sm {
        padding-bottom: 0;
        padding-top: calc(100px + 3rem + (#{$unit} / 2));
      }
    }

    &__subheader {
      padding-bottom: $unit/2;
      color: $color3;
    }

    &__text {
      color: $color4;
      padding-right: $unit/2;
      padding-bottom: $unit;
    }

    &__header, &__subheader, &__text {
      padding-left: calc(150px + 3rem + (#{$unit} / 2)); // avatar width + avatar left padding + padding away from avatar

      @include sm {
        padding-left: $unit/2;
        padding-right: $unit/2;
      }
    }

    &__avatar {
      width: 150px;
      float: left;
      overflow: hidden;
      border-radius: 50%;
      border: 2px $color1 solid;
      position: absolute;
      margin-left: $unit;
      margin-top: $unit*2;

      @include sm {
        margin: 2.25rem auto 0 auto;
        left: 0;
        right:0;
      }
    }

    @include sm {
      &__right, &__left {
        margin-left: 0;
        margin-right: 0;
      }

      &__avatar {
        margin: $unit/2 auto 0 auto;
      }

      &__text {
        padding-bottom: 0;
      }
    }
  } // End Story

  &--prefooter {
    text-align: center;
    width: 600px;
    padding: $unit;

    -webkit-box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);
    -moz-box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);
    box-shadow: 0px 1px 5px 1px rgba(48,48,48,0.3);

    background: white;

    p {
      margin-bottom: $unit;
    }

    .button-array {
      margin-bottom: 0;
      margin-top: 0;
    }

    @include sm {
      width: 100%;

      .button-array {
        &__button--secondary {
          margin-right: 0;
          margin-top: $unit/2;
        }
      }
    }
  }

  &--padded {
    &-both {
      padding: $unit 0;
    }
  }

  &--church-loans {
    .content-block--split__left {
      padding-top: 160px;

      @include sm {
        padding-top: 40px;
      }
    }

    .content-block__header--square-number__number {
      font-size:modular-scale(0);
    }

    .button-array__bordered {
      border-color:$color4;
    }
  }

  &--work {
    .content-block--split__left {
      padding-top: 6em;
      padding-bottom: 6em;

      @include sm {
        padding-top: $unit;
        padding-bottom: $unit/4;
        margin-bottom: 0;
      }
    }

    .button-array__bordered {
      border-color: $color4;
    }
  }

  &--tri-split {
    @include md-and-up {
      @include outer-container();

      &__left {
        @include span-columns(4);
        text-align: left;
        color: white;
        position: relative;
      }
      &__middle {
        @include span-columns(4);
        text-align: left;
        color: white;
        position: relative;
      }
      &__right {
        @include span-columns(4);
        text-align: left;
        color: white;
        position: relative;
      }
    }
    @include sm {
      &__text {
        clear: both;
        color: white;
        padding-bottom: $unit/2;
      }
    }
  }

  &__bordered {
    overflow: hidden;
    //@include clearfix;

    &--top {
      padding-top: $unit;
      border-top: 1px $color4 solid;

    }
    &--bottom {
      border-bottom: 1px $color4 solid;
      padding-bottom: $unit;
    }
    &--both {
      border-top: 1px $color4 solid;
      border-bottom: 1px $color4 solid;
      padding-top: $unit;
      padding-bottom: $unit;
      margin-bottom: $unit/2;

      @include sm {
        padding-bottom: 0;
      }
    }
  }
}

/* banded */
.banded {
  margin-top: $unit*2;
  padding-top: $unit*1.5;
  padding-bottom: $unit*1.5;

  &--image {
    background: url(/images/prefooter.jpg) center center no-repeat;
    background-color: grey;
    background-size: cover;

    @include sm {
      background-image: none;
    }
  }

  @include sm {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Testimonial Section */

.testimonial {
  text-align: center;
  overflow: hidden;
  padding-left: $unit;
  margin-top: $unit;

  &.container {
  padding-left: 0;
  }

  &__header {
    font: bold modular-scale(3) $font-header;
    margin-bottom: $unit/4;
    color: white;
    text-align: left;
    margin-top: $unit/2;

    &--dark {
      color: $color3;
    }

    @include sm {
      text-align: center;
      color: $color3;
    }
  }

  &__subheader {
    font: modular-scale(0) $font-header;
    margin-bottom: $unit/2;
    color: $color1;
    text-align: left;
    font-style: italic;


    @include sm {
      overflow: hidden;
      text-align: center;
    }
  }

  &__text {
    text-align: left;
    font-style: italic;
    font-size: modular-scale(2);
    clear: both;
    padding-top: $unit;
    color: white;

    &--dark {
      color: $color3;
      font-size: modular-scale(1);
    }

    @include sm {
      color: $color3;
      text-align: center;
      padding-bottom: $unit;
    }
  }

  &__avatar {
    width: 100px;
    float: left;
    margin-right: $unit/2;
    border-radius: 50%;
    border: 2px $color1 solid;

    @include sm {
      margin-top: $unit*.85;
      float: none;
      margin-right: 0;
    }
  }

  @include sm {
    text-align: center;
    padding-top: 0;
    padding-left: 0;
    margin-top: 0;
  }
}

.form-download {
  padding-top: $unit;
}
.responsibility-list {

  @include md-and-up {
    column-count: 2;
    column-gap: $unit*1.5;
  }
  li {
    @extend %bulleted;
    display: inline-block;
    width: 100%;
    margin-bottom: $unit/2;
  }
  @include sm {
    text-align: left;
  }
}